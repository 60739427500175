import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ParseJwt } from '../../../core/utils/functions'
import Api from './CodigosServices'
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Loading from "../../../core/utils/loading";
import showToast from '../../../core/utils/showToast';
import { config } from "../../../core/config/config";
import { Link } from 'react-router-dom';
import PdfViewerModal from '../components/modal-pdf/pdf-viewer-modal';

const base_url_files_contract = config.baseUrlFilesContract;

const Codigos = () => {

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const { register: registerSearch, handleSubmit: handleSubmitSearch, setValue: setValueSearch, reset: resetSearch, formState: { errors: errorsSearch } } = useForm();

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    /**Modal */
    const [isOpen, setIsOpen] = useState(false);
    const [currentFile, setCurrentFile] = useState("");

    /***Paginador */
    const [inicioPagina, setInicioPagina] = useState(0);
    const [finPagina, setFinPagina] = useState(9);
    const [cambioPagina, setCambioPagina] = useState(5);
    const [paginador, setPaginador] = useState([]);
    const [cantPaginas, setCantPaginas] = useState(0);
    const [options, setOptions] = useState({
        limit: 10,
        offset: 0
    })

    const itemsPaginator = (cant) => {
        const num = cant || 1;
        return Array.from({ length: num }, (_, i) => i + 1);
    };

    const cambiarPagina = (limit = 5, currentIndex = 0) => {
        if (currentIndex) {
            const offset = (limit * currentIndex) - limit;
            setOptions(prevOptions => ({ ...prevOptions, offset }));

            let nuevoInicioPagina = currentIndex;

            if (cantPaginas - currentIndex < cambioPagina) {
                nuevoInicioPagina = cantPaginas - (cambioPagina + 1);
            } else if (currentIndex > 1) {
                nuevoInicioPagina = currentIndex - 2;
            }

            if (nuevoInicioPagina <= 1) {
                nuevoInicioPagina = 0;
            }

            setInicioPagina(nuevoInicioPagina);

            if (cantPaginas > 1) {
                let nuevoFinPagina = cantPaginas + 1;
                if (cantPaginas > (cambioPagina + 1)) {
                    nuevoFinPagina = (cambioPagina + 1) + nuevoInicioPagina;
                }
                setFinPagina(nuevoFinPagina);
            }

            loadData();
        }
    };

    function generateUniqueCode() {
        const now = new Date();

        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const randomPart = Math.floor(100 + Math.random() * 900);

        const uniqueCode = `${year}${month}${day}${seconds}${randomPart}`;

        return uniqueCode;
    }

    const handleGenerateCode = () => {
        const newCode = generateUniqueCode();
        setValue('codigo', newCode);
    };

    const handdleAddCode = async (datos) => {

        if (datos.archivo_contrato.length == 0) {
            showToast('Se debe seleccionar el contrato', "error");
            return;
        }

        const dataForm = new FormData();
        for (let i = 0; i < datos.archivo_contrato.length; i++) {
            dataForm.append('archivos', datos.archivo_contrato[i], datos.archivo_contrato[i].name);
        }

        dataForm.append("codigo", datos.codigo);
        dataForm.append("usuarioId", user.sub);

        const response = await Api.CrearCodigo(dataForm);

        reset();
        // setData([...data, response.data]);
        loadData();
    }

    const handdleEstado = async (data) => {
        setLoading(true);

        var response;

        if (data.activo == '1') {
            response = await Api.cambiarEstado(data, '0');
        } else {
            response = await Api.cambiarEstado(data, '1');
        }

        if (!response.success) {
            setLoading(false);
            return;
        }

        setLoading(false);
        loadData();
    }

    const handdleMarcarTerminado = async (data) => {
        setLoading(true);

        var response;

        if (data.activo == '1') {
            response = await Api.cambiarEstado(data, '2');
            if (!response.success) {
                setLoading(false);
                return;
            }
        } else if (data.activo == '2') {
            response = await Api.cambiarEstado(data, '1');
            if (!response.success) {
                setLoading(false);
                return;
            }
        }

        setLoading(false);
        loadData();
    }

    const loadData = async () => {
        setLoading(true);
        const response = await Api.ObtenerCodigos(options);
        if (!response.success) {
            setLoading(false);
            setError(true);
            return;
        }
        setData(response.data.datos);
        setPaginador(response.data);
        setLoading(false);
    }

    const openModal = (fileName) => {
        setIsOpen(true)
        setCurrentFile(fileName)
    };

    const closeModal = () => setIsOpen(false);

    useEffect(() => {
        const dataUser = ParseJwt(localStorage.getItem('token'));
        setUser(dataUser);
        loadData().then();

        if (paginador?.pages) {
            setCantPaginas(paginador.pages);
        }

    }, [options, isOpen, currentFile]);

    return (
        <>
            <ToastContainer />
            <PdfViewerModal isOpen={isOpen} closeModal={() => closeModal()} fileName={currentFile} />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Codigos</h1>
            </div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div className="card border-0 shadow-sm">
                        <div className="card-header border-0">
                            <h3>Generar codigo</h3>
                            <div className="card-options">...</div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(handdleAddCode)} >
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="archivo_contrato" className="form-label">Contrato</label>
                                        <input {...register("archivo_contrato")} className={`form-control ${errors.archivo_contrato ? 'is-invalid' : ''}`} type="file" accept=".pdf" id="archivo_contrato" />
                                        <div className="invalid-feedback">
                                            {errors.archivo_contrato?.message}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="codigo" className="form-label">Codigo</label>
                                        <div className="input-group mb-3">
                                            <input {...register("codigo", { required: "El codigo es obligatorio" })} placeholder="Codigo formulario" type="text" className={`form-control ${errors.codigo ? 'is-invalid' : ''}`} id="codigo" disabled />
                                            <button onClick={handleGenerateCode} className="btn btn-primary btn-outline-secondary" type="button" id="button-addon2" name="Generar Código">
                                                <i className="bi bi-lightning-charge-fill text-white"></i>
                                            </button>
                                            <div className="invalid-feedback">
                                                {errors.codigo?.message}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn btn-primary text-center" type="submit">
                                        Guardar
                                    </button>
                                </div>
                            </form>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="card border-0 shadow-sm">
                        <div className="card-header border-0">
                            <h3>Códigos generados</h3>
                        </div>
                        <div className="card-body">
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                            <fieldset className="border rounded-3 mt-2 p-3">
                                <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1rem" }} >Filtrar busqueda</legend>
                                <div className='row'>
                                    <div className='col'>
                                        <label className="form-label">Mostrar</label>
                                        <select
                                            {...registerSearch("mostrar")}
                                            className='form-select'
                                            onChange={(e) => {
                                                const limit = e.target.value;
                                                setOptions(prevOptions => ({ ...prevOptions, limit }));
                                            }}
                                        >
                                            <option defaultValue={10} selected>10</option>
                                            <option defaultValue={20}>20</option>
                                            <option defaultValue={50}>50</option>
                                        </select>
                                    </div>
                                    <div className='col'>
                                        <label className="form-label">Codigo</label>
                                        <div className="input-group mb-3">
                                            <button className="btn btn-primary btn-outline-secondary" type="button" name="Buscar codigo">
                                                <i className="bi bi-search text-white"></i>
                                            </button>
                                            <input
                                                {...registerSearch("codigo")}
                                                onChange={(e) => {
                                                    const codigo = e.target.value;
                                                    setOptions(prevOptions => ({ ...prevOptions, codigo }));
                                                }}
                                                placeholder="Codigo formulario"
                                                type="text"
                                                className={`form-control`} id="codigobsucar"
                                            />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <label className="form-label">Estado</label>
                                        <select
                                            {...registerSearch("estado")} className='form-select'
                                            onChange={(e) => {
                                                const estado = e.target.value;
                                                setOptions(prevOptions => ({ ...prevOptions, estado }));
                                            }}
                                        >
                                            <option value="" selected>--- Seleccione estado ---</option>
                                            <option defaultValue={0}>Inactivo</option>
                                            <option defaultValue={1}>Activo</option>
                                            <option defaultValue={2}>Formulario terminado</option>
                                        </select>
                                    </div>
                                </div>
                            </fieldset>

                            <div>
                                {
                                    loading ? (
                                        <Loading />
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th className='text-center' scope="col">Codigo</th>
                                                        <th className='text-center' scope="col">Contrato <br />acepado?</th>
                                                        <th className='text-center' scope="col">Estado</th>
                                                        <th className='text-center' scope="col">Form. Terminado</th>
                                                        <th className='text-center' scope="col">Activo</th>
                                                        <th className='text-center' scope="col">Fecha creación</th>
                                                        {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    item.archivoContrato ? (
                                                                        <a href='#' onClick={() => openModal(item.archivoContrato)} >
                                                                            <i className="bi bi-filetype-pdf text-danger" style={{ fontSize: "22px" }}></i>
                                                                        </a>
                                                                    ) : (
                                                                        <i className="bi bi-filetype-pdf" style={{ fontSize: "22px", color: "grey" }} title='No hay contrato'></i>
                                                                    )
                                                                }&nbsp;{item.codigo}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.codigosContrato.length > 0 ? (
                                                                        <div class="list-group">
                                                                            <label class="list-group-item d-flex gap-3">
                                                                                <i className=" bi bi-check2-square text-success" style={{ "fontSize": "1.375em" }}></i>
                                                                                <span class="pt-1 form-checked-content">
                                                                                    <strong>Aceptado</strong>
                                                                                    <small class="d-block text-body-secondary">
                                                                                        <i class="bi bi-calendar-event"></i> &nbsp;
                                                                                        {format(new Date(item.codigosContrato[0].fechaRegistro), 'dd/MM/yyyy hh:mm a')}
                                                                                    </small>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    ) : (
                                                                        <div></div>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>{item.estado}</td>
                                                            <td className='text-center'>
                                                                <div className="form-check form-switch">
                                                                    {
                                                                        item.activo == '2' ? (
                                                                            <input className="form-check-input float-start" type="checkbox" role="switch" defaultChecked={true} onChange={() => handdleMarcarTerminado(item)} />
                                                                        ) : (
                                                                            <input className="form-check-input float-start" type="checkbox" role="switch" onChange={() => handdleMarcarTerminado(item)} />
                                                                        )
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className='text-center'>
                                                                <div className="form-check form-switch">
                                                                    {
                                                                        item.activo == '1' ? (
                                                                            <input className="form-check-input float-start" type="checkbox" role="switch" defaultChecked={true} onChange={() => handdleEstado(item)} />
                                                                        ) : (
                                                                            <input className="form-check-input float-start" type="checkbox" role="switch" onChange={() => handdleEstado(item)} />
                                                                        )
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>{format(new Date(item.fechaRegistro), 'dd/MM/yyyy')}</td>
                                                            {/* <td className='text-center text-danger'>
                                                                <i className="bi bi-trash fw-bold" style={{ cursor: 'pointer' }}></i>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <button
                                                    className={`btn btn-outline-primary rounded-circle p-0 paginator-button lh-1`}
                                                    type="button"
                                                    onClick={() => cambiarPagina(paginador?.limit, 1)}
                                                >
                                                    <i className="bi bi-chevron-double-left"></i>
                                                </button>
                                                <button
                                                    className={`btn btn-outline-primary rounded-circle p-0 paginator-button lh-1`}
                                                    type="button"
                                                    onClick={() => cambiarPagina(paginador?.limit, paginador?.previousPage)}
                                                >
                                                    <i className="bi bi-chevron-left"></i>
                                                </button>
                                                {
                                                    (() => {
                                                        return itemsPaginator(paginador?.pages).map((_, currentElementIndex) => {
                                                            if (currentElementIndex >= inicioPagina && currentElementIndex <= finPagina) {
                                                                return (
                                                                    <button
                                                                        key={currentElementIndex}
                                                                        className={`btn btn-outline-primary rounded-circle p-0 paginator-button lh-1 ${paginador?.currentPage == (currentElementIndex + 1) ? "active" : ""}`}
                                                                        type="button"
                                                                        onClick={() => cambiarPagina(paginador?.limit, currentElementIndex + 1)}
                                                                    >
                                                                        {currentElementIndex + 1}
                                                                    </button>
                                                                );
                                                            }
                                                        })
                                                    })()
                                                }
                                                <button
                                                    className={`btn btn-outline-primary rounded-circle p-0 paginator-button lh-1`}
                                                    type="button"
                                                    onClick={() => cambiarPagina(paginador?.limit, paginador?.nextPage)}
                                                >
                                                    <i className="bi bi-chevron-right"></i>
                                                </button>
                                                <button
                                                    className={`btn btn-outline-primary rounded-circle p-0 paginator-button lh-1`}
                                                    type="button"
                                                    onClick={() => cambiarPagina(paginador?.limit, paginador?.pages)}
                                                >
                                                    <i className="bi bi-chevron-double-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}

export default Codigos;