import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ParseJwt } from '../../../core/utils/functions'
import Api from '../Formularios/FormulariosServices'
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Loading from "../../../core/utils/loading";
import { useParams } from 'react-router-dom';
import DisplayDataLocal from '../../../core/utils/displayItemsLocal';

const FormularioDetalle = () => {

    const { id } = useParams();

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    const [items, setItems] = useState([]);
    const [items2, setItems2] = useState([]);
    const [items3, setItems3] = useState([]);
    const [items4, setItems4] = useState([]);
    const [items5, setItems5] = useState([]);
    const [items6, setItems6] = useState([]);
    const [items7, setItems7] = useState([]);
    const [items8, setItems8] = useState([]);
    const [items9, setItems9] = useState([]);
    const [items10, setItems10] = useState([]);
    const [items11, setItems11] = useState([]);
    const [items12, setItems12] = useState([]);

    const { register: registerForm1, handleSubmit: handleSubmitForm1, reset: reset1, formState: { errors: errors } } = useForm();
    const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: reset2, formState: { errors: errors2 } } = useForm();
    const { register: registerForm3, handleSubmit: handleSubmitForm3, reset: reset3, formState: { errors: errors3 } } = useForm();
    const { register: registerForm4, handleSubmit: handleSubmitForm4, reset: reset4, formState: { errors: errors4 } } = useForm();
    const { register: registerForm5, handleSubmit: handleSubmitForm5, reset: reset5, formState: { errors: errors5 } } = useForm();
    const { register: registerForm6, handleSubmit: handleSubmitForm6, reset: reset6, formState: { errors: errors6 } } = useForm();
    const { register: registerForm7, handleSubmit: handleSubmitForm7, reset: reset7, formState: { errors: errors7 } } = useForm();
    const { register: registerForm8, handleSubmit: handleSubmitForm8, reset: reset8, formState: { errors: errors8 } } = useForm();
    const { register: registerForm9, handleSubmit: handleSubmitForm9, reset: reset9, formState: { errors: errors9 } } = useForm();
    const { register: registerForm10, handleSubmit: handleSubmitForm10, reset: reset10, formState: { errors: errors10 } } = useForm();
    const { register: registerForm11, handleSubmit: handleSubmitForm11, reset: reset11, formState: { errors: errors11 } } = useForm();
    const { register: registerForm12, handleSubmit: handleSubmitForm12, reset: reset12, formState: { errors: errors12 } } = useForm();

    const onSubmitForm1 = async (data) => { }
    const onSubmitForm2 = async (data) => { }
    const onSubmitForm3 = async (data) => { }
    const onSubmitForm4 = async (data) => { }
    const onSubmitForm5 = async (data) => { }
    const onSubmitForm6 = async (data) => { }
    const onSubmitForm7 = async (data) => { }
    const onSubmitForm8 = async (data) => { }
    const onSubmitForm9 = async (data) => { }
    const onSubmitForm10 = async (data) => { }
    const onSubmitForm11 = async (data) => { }
    const onSubmitForm12 = async (data) => { }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const loadData = async () => {
        setLoading(true);
        const response = await Api.ObtenerFormulario(id);
        if (!response.success) {
            setLoading(false);
            setError(true);
            return;
        }

        setItems(response.data.formInformacionPersonal)
        setItems2(response.data.formDireccionesResidencia)
        setItems3(response.data.formEstudios)
        setItems4(response.data.formEmpleos)
        setItems5(response.data.formPadresHermanos)
        setItems6(response.data.formInformacionMarital)
        setItems7(response.data.formInformacionHijos)
        setItems8(response.data.formSalidaPais)
        setItems9(response.data.formReligion)
        setItems10(response.data.formGrupoEtnico)
        setItems11(response.data.formIdiomaNativo)
        setItems12(response.data.formOtrosIdiomas)

        setData(response.data);
        setLoading(false);
    }

    useEffect(() => {
        const dataUser = ParseJwt(localStorage.getItem('token'));
        setUser(dataUser);
        loadData().then();

    }, []);

    return (
        <>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Formulario Detalle</h1>
            </div>
            <div>
                {
                    loading ? (
                        <Loading />
                    ) : (
                        <main className="pb-4 pt-0">
                            <div className="accordion shadow mt-4" id="accordionInformacion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                            <span>1. Información personal</span>
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sección completada"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseZero" className="accordion-collapse collapse show">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm1(onSubmitForm1)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items.length == 0 && (
                                                                <></>
                                                            )
                                                        }
                                                        {
                                                            items.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Primer nombre</th>
                                                                                <th scope="col">Segundo nombre</th>
                                                                                <th scope="col">Primer apellido</th>
                                                                                <th scope="col">Segundo apellido</th>
                                                                                <th scope="col">Ciudad de nacimiento</th>
                                                                                <th scope="col">Numero de telefono</th>
                                                                                <th scope="col">Tipo de ingreso</th>
                                                                                <th scope="col">Archivos</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            2. Direcciones de residencia de los ultimos 5 años
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items2.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm2(onSubmitForm2)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items2.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th colSpan={7}></th>
                                                                                <th colSpan={2}>Fecha de llegada</th>
                                                                                <th>Fecha de salida</th>
                                                                                <th></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Dirección</th>
                                                                                <th scope="col">Número de Apartamento</th>
                                                                                <th scope="col">Ciudad</th>
                                                                                <th scope="col">Estado/Provincia</th>
                                                                                <th scope="col">Pais</th>
                                                                                <th scope="col">Codigo Postal</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items2} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            3. Estudios realizados y culminados
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items3.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm3(onSubmitForm3)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items3.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th colSpan={5}></th>
                                                                                <th colSpan={2}>Fecha de inicio</th>
                                                                                <th>Fecha de culminación</th>
                                                                                <th></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Institución</th>
                                                                                <th scope="col">Nivel academico</th>
                                                                                <th scope="col">Ciudad</th>
                                                                                <th scope="col">Pais</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items3} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            4. Empleos de los ultimos 5 años
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items4.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm4(onSubmitForm4)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>

                                                        {
                                                            items4.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th colSpan={5}></th>
                                                                                <th colSpan={2}>Fecha de inicio</th>
                                                                                <th>Fecha de culminación</th>
                                                                                <th></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Nombre de la empresa</th>
                                                                                <th scope="col">Cargo/Ocupación</th>
                                                                                <th scope="col">Ciudad</th>
                                                                                <th scope="col">Pais</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items4} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            5. Nombres completos de padres y  hermanos de mayor a menor
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items5.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm5(onSubmitForm5)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>

                                                        {
                                                            items5.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Parentezco</th>
                                                                                <th scope="col">Primer nombre</th>
                                                                                <th scope="col">Segundo nombre</th>
                                                                                <th scope="col">Primer apellido</th>
                                                                                <th scope="col">Segundo apellido</th>
                                                                                <th scope="col">Ciudad de nacimiento</th>
                                                                                <th scope="col">Pais de nacimiento</th>
                                                                                <th scope="col">Ciudad donde vive actualmente</th>
                                                                                <th scope="col">Pais donde vive actualmente</th>
                                                                                <th scope="col">Status migratorio</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items5} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            6. Información marital
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items6.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm6(onSubmitForm6)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items6.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Estado marital</th>
                                                                                <th scope="col">Primer nombre</th>
                                                                                <th scope="col">Segundo nombre</th>
                                                                                <th scope="col">Primer apellido</th>
                                                                                <th scope="col">Segundo apellido</th>
                                                                                <th scope="col">Fecha de nacimiento (DD/MM/AAAA)</th>
                                                                                <th scope="col">Ciudad de nacimiento</th>
                                                                                <th scope="col">Pais de nacimiento</th>
                                                                                <th scope="col">Nacionalidad</th>
                                                                                <th scope="col">Fecha de matrimonio (DD/MM/AAAA)</th>
                                                                                <th scope="col">Ciudad donde se casaron</th>
                                                                                <th scope="col">Pais donde se casaron</th>
                                                                                <th scope="col">En USA</th>
                                                                                <th scope="col">Incluir persona</th>
                                                                                <th scope="col">Ciudad Actual</th>
                                                                                <th scope="col">Pais Actual</th>
                                                                                <th scope="col">Archivos</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items6} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">
                                            7. Información hijos
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items7.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseEigth" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm7(onSubmitForm7)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items7.length == 0 && (
                                                                <>
                                                                </>
                                                            )
                                                        }
                                                    </form>
                                                    {
                                                        items7.length > 0 && (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                    <thead className="table-dark">
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Hijos</th>
                                                                            <th scope="col">Primer nombre</th>
                                                                            <th scope="col">Segundo nombre</th>
                                                                            <th scope="col">Primer apellido</th>
                                                                            <th scope="col">Segundo apellido</th>
                                                                            <th scope="col">Fecha de nacimiento (DD/MM/AAAA)</th>
                                                                            <th scope="col">Ciudad de nacimiento</th>
                                                                            <th scope="col">Pais de nacimiento</th>
                                                                            <th scope="col">Nacionalidad</th>
                                                                            <th scope="col">Estado marital</th>
                                                                            <th scope="col">En USA</th>
                                                                            <th scope="col">Incluir persona</th>
                                                                            <th scope="col">Ciudad donde vive</th>
                                                                            <th scope="col">Pais donde vive</th>
                                                                            <th scope="col">Archivos</th>
                                                                            {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <DisplayDataLocal items={items7} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                </table>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            8. Fecha de salida del pais
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items8.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm8(onSubmitForm8)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items8.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Dia</th>
                                                                                <th scope="col">Mes</th>
                                                                                <th scope="col">Año</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items8} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            9. Religión
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items9.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm9(onSubmitForm9)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items9.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Religión</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items9} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                            10. Grupo Etnico
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items10.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseTen" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm10(onSubmitForm10)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items10.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Grupo etnico</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items10} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                            11. Idioma nativo
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items11.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseEleven" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm11(onSubmitForm11)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items11.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Idioma nativo</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items11} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                            12. Otro idioma
                                            <span className="ms-auto d-flex align-items-center">
                                                {
                                                    items12.length > 0 && (
                                                        <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                    )
                                                }
                                            </span>
                                        </button>
                                    </h2>
                                    <div id="collapseTwelve" className="accordion-collapse collapse">
                                        <div className="accordion-body">
                                            <div className="row g-3">
                                                <div className="col-md-12 col-lg-12">
                                                    <form onSubmit={handleSubmitForm12(onSubmitForm12)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                        {
                                                            items12.length == 0 && (
                                                                <>
                                                                    <h5>No se lleno esta sección</h5>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            items12.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Otro idioma</th>
                                                                                {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayDataLocal items={items12} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    )
                }
            </div>
            <br />
        </>
    )
}

export default FormularioDetalle;