import React from 'react'

import { config } from "../../core/config/config";
import { Link } from 'react-router-dom';
import Api from '../../pages/PagesLanding/services/FormServices'
import { format } from 'date-fns';
import { isInvalidDate } from './functions';

const base_url_files = config.baseUrlFiles;

const DisplayLocalStorageData = ({ items, setItems, localStorageItem, exceptions = [] }) => {

    const handleDelete = async (itemToDelete) => {

        const itemsArray = Object.entries(itemToDelete);

        await Promise.all(
            itemsArray.map(async ([key, item], valueIndex) => {
                if (key === 'archivos' && Array.isArray(item)) {
                    return Promise.all(
                        item.map(async (archivo, archivoIndex) => {
                            await Api.BorrarArchivo(archivo.nombreArchivo);
                        })
                    );
                }
            })
        );

        const updatedItems = items.filter((item) => item !== itemToDelete);
        setItems(updatedItems);
        localStorage.setItem(localStorageItem, JSON.stringify(updatedItems));
    };

    return (
        <tbody>
            {items.map((item, index) => (
                <tr key={`item-${index}`}>
                    <td className='align-middle'>{index + 1}</td>
                    {Object.entries(item).map(([key, value], valueIndex) => {
                        if (key === 'archivos' && Array.isArray(value) && value.length > 0) {
                            return (
                                <td key={`archivos-${index}-${valueIndex}`} className='align-middle'>
                                    {
                                        value.map((archivo, archivoIndex) => (
                                            <div className='mt-1' key={`archivo-${index}-${archivoIndex}`}>
                                                <span className='fw-bold'>{archivo.categoria}: </span>
                                                <Link to={`${base_url_files}/${archivo.nombreArchivo}`} target='_blank'>
                                                    {archivo.nombre}
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </td>
                            );
                        } else {
                            if (!exceptions.includes(key)) {
                                let valueText = value;
                                /**Si viene una fecha, cambiar el formato */
                                if (key.includes('fecha')) {
                                    if (isInvalidDate(value)) {
                                        valueText = value;
                                    } else {
                                        const date = new Date(`${value}T00:00:00`);
                                        valueText = format(date, 'dd/MM/yyyy')
                                    }
                                }
                                // if (key.includes('fecha')) {
                                //     const date = new Date(value);
                                //     if (isNaN(date.getTime())) {
                                //         valueText = value;
                                //     } else {
                                //         const date = new Date(`${value}T00:00:00`);
                                //         valueText = format(date, 'dd/MM/yyyy')
                                //     }
                                // }
                                return <td key={`value-${index}-${valueIndex}`} className='align-middle'>{valueText}</td>;
                            }
                            // return <td key={`value-${index}-${valueIndex}`} className='align-middle'>{value}</td>;
                        }
                    })}
                    <td className='text-center align-middle text-danger'>
                        <i
                            className="bi bi-trash fw-bold"
                            onClick={() => handleDelete(item)}
                        />
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default DisplayLocalStorageData;