import React, { useState } from "react";
import Modal from "react-modal";
import { config } from "../../../../core/config/config";

// Modal.setAppElement("#root");

const base_url_files_contract = config.baseUrlFilesContract;

const PdfViewerModal = ({ isOpen, closeModal, fileName }) => {

    const pdfUrl = base_url_files_contract + "/" + fileName;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="PDF Viewer"
                style={{
                    content: {
                        width: "80%",
                        height: "80%",
                        margin: "auto",
                        borderRadius: "10px",
                        overflowY: "hidden"
                    },
                    overlay: { zIndex: 1000 }
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    {fileName}
                    <a href="#" className="" onClick={closeModal} style={{ fontSize: "25px" }}>
                        <i class="bi bi-x"></i>
                    </a>
                </div>
                <iframe
                    src={`${pdfUrl}`}
                    title="PDF Viewer"
                    style={{ width: "100%", height: "90%" }}
                ></iframe>
            </Modal>
        </div>
    );
};

export default PdfViewerModal;